import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaFacebookF, FaLinkedinIn, FaEye, FaEyeSlash } from 'react-icons/fa';
import { loginUser } from '../routes/UserRoutes';
import StatusDialog from './Dialog';

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [dialogStatus, setDialogStatus] = useState(null); // 'loading', 'success', 'error', or null
  const [errorMessage, setErrorMessage] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  

  const natureImages = [
    "/api/placeholder/800/600?text=Nature+Image+1",
    "/api/placeholder/800/600?text=Nature+Image+2",
    "/api/placeholder/800/600?text=Nature+Image+3",
    "/api/placeholder/800/600?text=Nature+Image+4",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => 
        prevIndex === natureImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDialogStatus('loading');
  
    try {
      const response = await loginUser(username, password);
      if (response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        if (rememberMe) {
          localStorage.setItem('rememberMe', 'true');
          localStorage.setItem('rememberedUsername', username);
        } else {
          localStorage.removeItem('rememberMe');
          localStorage.removeItem('rememberedUsername');
        }
        setDialogStatus('success');
      } else {
        setErrorMessage(response.message || 'Invalid email or password');
        setDialogStatus('error');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setErrorMessage(err.message || 'An error occurred during login');
      setDialogStatus('error');
    }
  };

  const handleSuccessDialogClose = () => {
    setDialogStatus(null);
    navigate('/Dashboard');
  };

  const handleErrorDialogClose = () => {
    setDialogStatus(null);
  };

  useEffect(() => {
    const rememberedUsername = localStorage.getItem('rememberedUsername');
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-gradient-to-r from-blue-600 to-purple-700 p-6 overflow-hidden">
      <div className="relative w-full max-w-6xl h-full max-h-[780px]">
        {/* Enhanced background designs */}
        <div className="absolute inset-0 bg-white opacity-5 rounded-3xl transform rotate-3"></div>
        <div className="absolute inset-0 bg-white opacity-5 rounded-3xl transform -rotate-3"></div>
        <div className="absolute -top-20 -left-20 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob"></div>
        <div className="absolute -bottom-20 -right-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-2000"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80 h-80 bg-blue-300 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-4000"></div>
        <div className="absolute top-1/4 -left-10 w-48 h-48 bg-green-300 rounded-full mix-blend-multiply filter blur-2xl opacity-30 animate-pulse"></div>
        <div className="absolute bottom-1/4 -right-10 w-48 h-48 bg-red-300 rounded-full mix-blend-multiply filter blur-2xl opacity-30 animate-pulse animation-delay-1000"></div>
        <div className="absolute top-3/4 left-1/4 w-32 h-32 bg-indigo-300 rounded-full mix-blend-multiply filter blur-xl opacity-30 animate-ping"></div>
        
        {/* Cool geometric shapes */}
        <svg className="absolute top-0 left-0 w-40 h-40 text-indigo-500 opacity-30" viewBox="0 0 100 100" fill="currentColor">
          <polygon points="0,0 100,0 50,100" />
        </svg>
        <svg className="absolute bottom-0 right-0 w-40 h-40 text-pink-500 opacity-30" viewBox="0 0 100 100" fill="currentColor">
          <circle cx="50" cy="50" r="50" />
        </svg>
        <svg className="absolute top-1/2 left-1/4 w-32 h-32 text-yellow-500 opacity-30 transform rotate-45" viewBox="0 0 100 100" fill="currentColor">
          <rect width="100" height="100" />
        </svg>
        
        {/* Main container */}
        <div className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl overflow-hidden w-full h-full flex flex-col md:flex-row relative z-10">
          {/* Left side - Image Carousel (hidden on mobile, 62% on larger screens) */}
          <div className="hidden md:block md:w-[62%] relative">
            <div className="absolute inset-0 overflow-hidden">
              {natureImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Nature ${index + 1}`}
                  className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
                    index === currentImageIndex ? 'opacity-100' : 'opacity-0'
                  }`}
                />
              ))}
            </div>
            {/* Enhanced hero text overlay */}
            <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-br from-blue-800 via-purple-700 to-pink-600 bg-opacity-80">
              <div className="text-center text-white p-8">
                <h1 className="text-5xl font-bold mb-4 leading-tight">Welcome to ByteBuzz</h1>
                <p className="text-2xl mb-6 font-light">Empowering e-commerce brands with innovative solutions</p>
                <div className="w-24 h-1 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 mx-auto mb-6"></div>
                <p className="text-lg opacity-80 font-light">Experience the future of e-commerce</p>
              </div>
            </div>
          </div>

          {/* Right side - Login Form (100% on mobile, 38% on larger screens) */}
          <div className="w-full md:w-[38%] p-8 flex flex-col justify-center">
            <div className="flex justify-center mb-6">
              <img src="https://i.ibb.co/BL77mZr/Web-Photo-Editor.png" alt="ByteBuzz Logo" className="h-16" />
            </div>
            <h2 className="text-2xl font-bold text-center mb-6 text-gray-800">Welcome Back</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  id="username"
                  placeholder="Email or Phone"
                  required
                  className="block w-full px-4 py-3 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm transition duration-150 ease-in-out"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  required
                  className="block w-full px-4 py-3 pr-10 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-sm transition duration-150 ease-in-out"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash className="h-5 w-5 text-gray-400" /> : <FaEye className="h-5 w-5 text-gray-400" />}
                </button>
              </div>
              <div className="flex items-center justify-between text-sm">
                <div className="flex items-center">
                  <input
                    id="rememberMe"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="rememberMe" className="ml-2 block text-gray-700 text-sm">
                    Remember me
                  </label>
                </div>
                <a href="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500 text-sm transition duration-150 ease-in-out">
                  Forgot password?
                </a>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-md text-base font-semibold text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                >
                  {isLoading ? 'Logging in...' : 'Log In'}
                </button>
              </div>
            </form>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500 text-sm">Or continue with</span>
                </div>
              </div>
              <div className="mt-4 grid grid-cols-3 gap-3">
                <div>
                  <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition duration-150 ease-in-out">
                    <FaGoogle className="h-5 w-5 text-red-500" />
                  </a>
                </div>
                <div>
                  <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition duration-150 ease-in-out">
                    <FaFacebookF className="h-5 w-5 text-blue-600" />
                  </a>
                </div>
                <div>
                  <a href="#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 transition duration-150 ease-in-out">
                    <FaLinkedinIn className="h-5 w-5 text-blue-700" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {dialogStatus && (
        <StatusDialog
          status={dialogStatus}
          onSuccess={handleSuccessDialogClose}
          onClose={handleErrorDialogClose}
          errorMessage={errorMessage}
          action="login"
        />
      )}
    </div>
  );
};

export default LoginPage;