import React, { useState, useEffect } from 'react';
import { AlertCircle, CheckCircle, Loader } from 'lucide-react';

const LoadingCard = ({ onComplete, action }) => {
  const [loadingText, setLoadingText] = useState('ByteBuzz');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    const maxDuration = 1000; // Reduced to 1 second

    const textInterval = setInterval(() => {
      setLoadingText(prev => {
        switch(prev) {
          case 'ByteBuzz': return 'ByteBuzz.';
          case 'ByteBuzz.': return 'ByteBuzz..';
          case 'ByteBuzz..': return 'ByteBuzz...';
          default: return 'ByteBuzz';
        }
      });
    }, 250);

    const progressInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min((elapsedTime / maxDuration) * 100, 100);
      setProgress(newProgress);

      if (elapsedTime >= maxDuration) {
        clearInterval(progressInterval);
        clearInterval(textInterval);
        onComplete();
      }
    }, 16); // ~60fps

    return () => {
      clearInterval(textInterval);
      clearInterval(progressInterval);
    };
  }, [onComplete]);

  return (
    <div className="bg-white rounded-2xl shadow-lg overflow-hidden float-animation p-6 w-80">
      <div className="text-center mb-4">
        <span className="text-3xl font-bold text-gray-800 loading-text">{loadingText}</span>
      </div>
      <div className="mb-4">
        <div className="w-full bg-gray-200 rounded-full h-2 overflow-hidden">
          <div 
            className="bg-indigo-600 h-full rounded-full transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
      <div className="flex justify-center mb-4">
        <Loader className="w-16 h-16 text-indigo-600 animate-spin" />
      </div>
      <p className="text-gray-600 text-center text-sm">Please wait while we process your {action}.</p>
    </div>
  );
};

const SuccessCard = ({ onSuccess, action }) => {
  useEffect(() => {
    const timer = setTimeout(onSuccess, 2000); // Reduced to 2 seconds
    return () => clearTimeout(timer);
  }, [onSuccess]);

  const getSuccessMessage = () => {
    switch (action) {
      case 'login':
        return 'You have successfully logged in.';
      case 'logout':
        return 'You have successfully logged out.';
      case 'save':
        return 'Your changes were successfully saved.';
      default:
        return 'Operation completed successfully.';
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-lg overflow-hidden float-animation p-6 w-80">
      <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4 success-wave">
        <CheckCircle className="w-16 h-16 text-green-500" />
      </div>
      <h2 className="text-2xl font-bold text-gray-800 mb-2 text-center">Success!</h2>
      <p className="text-gray-600 text-center text-sm">{getSuccessMessage()}</p>
    </div>
  );
};

const ErrorCard = ({ onClose, errorMessage }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 2000); // Reduced to 2 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="bg-white rounded-2xl shadow-lg overflow-hidden float-animation p-6 w-80">
      <div className="w-20 h-20 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4 error-shake">
        <AlertCircle className="w-16 h-16 text-red-500" />
      </div>
      <h2 className="text-2xl font-bold text-gray-800 mb-2 text-center">Error!</h2>
      <p className="text-gray-600 text-center text-sm">{errorMessage || "We couldn't complete your request."}</p>
    </div>
  );
};

const StatusDialog = ({ status, onSuccess, onClose, errorMessage, action }) => {
  const [showLoading, setShowLoading] = useState(status === 'loading');

  const handleLoadingComplete = () => {
    setShowLoading(false);
  };

  useEffect(() => {
    if (status === 'loading') {
      setShowLoading(true);
    }
  }, [status]);

  if (!status) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative">
        {showLoading && <LoadingCard onComplete={handleLoadingComplete} action={action} />}
        {!showLoading && status === 'success' && <SuccessCard onSuccess={onSuccess} action={action} />}
        {!showLoading && status === 'error' && <ErrorCard onClose={onClose} errorMessage={errorMessage} />}
      </div>
    </div>
  );
};

export default StatusDialog;