import axiosInstance from './axiosConfig';

let API_BASE_URL = process.env.REACT_APP_API_URL;

// if (API_BASE_URL) {
//     API_BASE_URL = API_BASE_URL.slice(1, -2);
// }

// Function to get the token from local storage
const getAuthToken = () => {
  return localStorage.getItem('token');
};

// Function to fetch data from API with platform
export const fetchDataFromAPI = async (platform) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/data?platform=${platform}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from API:', error);
    throw error;
   
  }
};

// Function to fetch order details
export const fetchOrderDetails = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/data`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

// Function to fetch dashboard data
export const fetchDashboardData = async () => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/orders`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

// Function to fetch PO data by PO number
export const fetchPodata = async (Ponumber) => {
  try {
    const token = getAuthToken();
    const response = await axiosInstance.get(`${API_BASE_URL}/items-by-po/${Ponumber}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order summaries:', error);
    throw error;
  }
};
