import React from 'react';
import { Rocket, Zap, Code, Cpu } from 'lucide-react';

const UnderConstruction = () => {
  return (
    <div className="h-screen bg-gradient-to-br from-teal-50 to-cyan-100 flex flex-col justify-center items-center p-4 relative overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute top-[10%] left-[10%] animate-pulse">
          <Zap className="text-teal-300 w-8 h-8 md:w-12 md:h-12" />
        </div>
        <div className="absolute bottom-[10%] right-[10%] animate-spin-slow">
          <Code className="text-cyan-300 w-10 h-10 md:w-16 md:h-16" />
        </div>
        <div className="absolute top-1/4 right-1/4 animate-bounce">
          <Cpu className="text-teal-200 w-6 h-6 md:w-10 md:h-10" />
        </div>
      </div>

      <div className="text-center z-10 max-w-xl mx-auto">
        <Rocket className="text-teal-600 w-16 h-16 md:w-20 md:h-20 mx-auto mb-6 md:mb-8 animate-float" />
        <h1 className="text-4xl md:text-6xl font-bold text-teal-900 mb-4 md:mb-6">
          Coming Soon
        </h1>
        <h2 className="text-lg md:text-xl font-light text-teal-700 mb-6 md:mb-8">
          We're building something exciting for you!
        </h2>
        <div className="mt-8 md:mt-12 inline-block">
          <p className="text-teal-800 text-lg md:text-xl font-semibold px-5 py-2 md:px-6 md:py-3 bg-white bg-opacity-50 rounded-full shadow-lg">
            ByteBuzz.in
          </p>
        </div>
      </div>

      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600;700&display=swap');

        body {
          font-family: 'Poppins', sans-serif;
          margin: 0;
          padding: 0;
          overflow: hidden;
        }

        @keyframes float {
          0%, 100% { transform: translateY(0px); }
          50% { transform: translateY(-20px); }
        }

        @keyframes spin-slow {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }

        .animate-float {
          animation: float 6s ease-in-out infinite;
        }

        .animate-spin-slow {
          animation: spin-slow 15s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default UnderConstruction;