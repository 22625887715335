import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import SummaryPage from './components/SummaryPage';
import PoTable from './components/PoTable';
import UnderConstruction from './components/UnderConstruction';
import Dashboard from './components/Dashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminDashboard from './components/AdminDashboard';
import AuthorizationDenied from './components/Authorizationdenied';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Redirect root to /login */}
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />}  />
          <Route path="/SummaryPage" element={<SummaryPage />}  />
          <Route path="/Potable" element={<PoTable />}  />
          <Route path="/DAS" element={<UnderConstruction />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Admin" element={<AdminDashboard />} />
          <Route path="/AuthorizationDenied" element={<AuthorizationDenied />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
