import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Switch, Tag, Divider, Tooltip, Space, Popconfirm } from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  UserOutlined, 
  MailOutlined, 
  TeamOutlined, 
  AppstoreOutlined, 
  CalendarOutlined, 
  SafetyOutlined, 
  CheckCircleOutlined, 
  CloseCircleOutlined, 
  DashboardOutlined, 
  FileTextOutlined, 
  BarChartOutlined, 
  ShoppingCartOutlined,
  SettingOutlined,
  PhoneOutlined,
  SaveOutlined,
  ManOutlined,
  WomanOutlined
} from '@ant-design/icons';

const { Option } = Select;

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingUser, setEditingUser] = useState(null);

  const platforms = ['Amazon', 'Flipkart', 'Blinkit', 'Meesho', 'Myntra'];
  const roles = ['Admin', 'Manager', 'User', 'Analyst', 'Support'];
  const departments = ['IT', 'HR', 'Finance', 'Marketing', 'Operations'];
  const navigations = ['Dashboard', 'User Management', 'Reports', 'Analytics', 'Order Management', 'Settings'];
  const genders = ['Male', 'Female', 'Other'];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    // Mock API call
    const mockUsers = [
      { id: 1, name: 'John Doe', email: 'john@example.com', role: 'Admin', department: 'IT', platforms: ['Amazon', 'Flipkart'], status: true, lastLogin: '2023-08-15', navigations: ['Dashboard', 'User Management', 'Reports', 'Analytics', 'Order Management', 'Settings'], phone: '+1234567890', gender: 'Male', birthDate: '1985-05-15' },
      { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'Manager', department: 'HR', platforms: ['Blinkit', 'Meesho'], status: false, lastLogin: '2023-07-30', navigations: ['Dashboard', 'Reports', 'Analytics'], phone: '+0987654321', gender: 'Female', birthDate: '1990-03-22' },
      { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'Analyst', department: 'Finance', platforms: ['Myntra'], status: true, lastLogin: '2023-08-20', navigations: ['Dashboard', 'Analytics'], phone: '+1122334455', gender: 'Female', birthDate: '1988-11-07' },
    ];
    setUsers(mockUsers);
  };

  const showModal = (user = null) => {
    setEditingUser(user);
    if (user) {
      form.setFieldsValue(user);
    } else {
      form.resetFields();
    }
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    if (editingUser) {
      const updatedUsers = users.map(user => 
        user.id === editingUser.id ? { ...user, ...values } : user
      );
      setUsers(updatedUsers);
      message.success('User updated successfully');
    } else {
      const newUser = { 
        id: users.length + 1, 
        ...values, 
        status: true,
        lastLogin: new Date().toISOString().split('T')[0],
      };
      setUsers([...users, newUser]);
      message.success('User created successfully');
    }
    setModalVisible(false);
    setEditingUser(null);
  };

  const handleDelete = (userId) => {
    setUsers(users.filter(user => user.id !== userId));
    message.success('User deleted successfully');
  };

  const handleStatusChange = (checked, userId) => {
    const updatedUsers = users.map(user =>
      user.id === userId ? { ...user, status: checked } : user
    );
    setUsers(updatedUsers);
    message.success(`User status updated to ${checked ? 'Active' : 'Inactive'}`);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <UserOutlined style={{ marginRight: 8, color: '#1890ff' }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => (
        <span>
          <MailOutlined style={{ marginRight: 8, color: '#52c41a' }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => (
        <Tag color={text === 'Admin' ? 'gold' : text === 'Manager' ? 'green' : 'blue'}>
          <SafetyOutlined style={{ marginRight: 4 }} />
          {text}
        </Tag>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      render: (text) => (
        <span>
          <TeamOutlined style={{ marginRight: 8, color: '#722ed1' }} />
          {text}
        </span>
      ),
    },
    {
      title: 'Platforms',
      dataIndex: 'platforms',
      key: 'platforms',
      render: (platforms) => (
        <span>
          <AppstoreOutlined style={{ marginRight: 8, color: '#faad14' }} />
          {Array.isArray(platforms) ? platforms.join(', ') : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Navigation Access',
      dataIndex: 'navigations',
      key: 'navigations',
      render: (navigations) => (
        <span style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
          {Array.isArray(navigations) ? navigations.map((nav) => (
            <Tooltip title={nav} key={nav}>
              <Tag color="cyan" style={{ margin: '2px', padding: '2px 4px' }}>
                {nav === 'Dashboard' && <DashboardOutlined />}
                {nav === 'User Management' && <UserOutlined />}
                {nav === 'Reports' && <FileTextOutlined />}
                {nav === 'Analytics' && <BarChartOutlined />}
                {nav === 'Order Management' && <ShoppingCartOutlined />}
                {nav === 'Settings' && <SettingOutlined />}
              </Tag>
            </Tooltip>
          )) : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Switch
          checked={status}
          onChange={(checked) => handleStatusChange(checked, record.id)}
          checkedChildren={<CheckCircleOutlined />}
          unCheckedChildren={<CloseCircleOutlined />}
        />
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      render: (date) => (
        <span>
          <CalendarOutlined style={{ marginRight: 8, color: '#eb2f96' }} />
          {date}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Edit User">
            <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this user?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete User">
              <Button icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="admin-dashboard" style={{ padding: '24px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
      <div style={{ backgroundColor: 'white', padding: '24px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
        <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '24px', color: '#1890ff', display: 'flex', alignItems: 'center' }}>
          <UserOutlined style={{ marginRight: '12px', fontSize: '24px' }} />
          User Management Dashboard
        </h2>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={() => showModal()} 
          style={{ marginBottom: 16 }}
        >
          Add User
        </Button>
        <Table 
          columns={columns} 
          dataSource={users} 
          rowKey="id" 
          pagination={{ pageSize: 5 }}
          style={{ marginTop: 16 }}
        />
      </div>

      <Modal
        title={
          <div style={{ display: 'flex', alignItems: 'center', color: '#1890ff' }}>
            {editingUser ? <EditOutlined style={{ marginRight: 8 }} /> : <PlusOutlined style={{ marginRight: 8 }} />}
            {editingUser ? "Edit User" : "Add New User"}
          </div>
        }
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input the name!' }]}
              style={{ width: '48%' }}
            >
              <Input prefix={<UserOutlined style={{ color: '#1890ff' }} />} placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please input the email!' },
                { type: 'email', message: 'Please enter a valid email!' }
              ]}
              style={{ width: '48%' }}
            >
              <Input prefix={<MailOutlined style={{ color: '#52c41a' }} />} placeholder="Email" />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Please select a role!' }]}
              style={{ width: '48%' }}
            >
              <Select placeholder="Select a role">
                {roles.map(role => (
                  <Option key={role} value={role}>{role}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="department"
              label="Department"
              rules={[{ required: true, message: 'Please select a department!' }]}
              style={{ width: '48%' }}
            >
              <Select placeholder="Select a department">
                {departments.map(dept => (
                  <Option key={dept} value={dept}>{dept}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="platforms"
            label="Platform Access"
            rules={[{ required: true, message: 'Please select at least one platform!' }]}
          >
            <Select mode="multiple" placeholder="Select platforms">
              {platforms.map(platform => (
                <Option key={platform} value={platform}>{platform}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="navigations"
            label="Navigation Access"
            rules={[{ required: true, message: 'Please select at least one navigation!' }]}
          >
            <Select mode="multiple" placeholder="Select navigations">
              {navigations.map(nav => (
                <Option key={nav} value={nav}>{nav}</Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: 'Please input the phone number!' }]}
              style={{ width: '48%' }}
            >
              <Input prefix={<PhoneOutlined style={{ color: '#1890ff' }} />} placeholder="Phone" />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: 'Please select a gender!' }]}
              style={{ width: '48%' }}
            >
              <Select placeholder="Select gender">
                {genders.map(gender => (
                  <Option key={gender} value={gender}>
                    {gender === 'Male' && <ManOutlined style={{ marginRight: 8 }} />}
                    {gender === 'Female' && <WomanOutlined style={{ marginRight: 8 }} />}
                    {gender}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="birthDate"
            label="Birth Date"
            rules={[{ required: true, message: 'Please input the birth date!' }]}
          >
            <Input type="date" style={{ width: '100%' }} />
          </Form.Item>
          <Divider />
          <Form.Item>
            <Button type="primary" htmlType="submit" block icon={<SaveOutlined />}>
              {editingUser ? 'Update User' : 'Create User'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminDashboard;