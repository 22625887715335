import React, { useState, useEffect } from 'react';
import { X, User, Lock, HelpCircle, Info, Mail, Bell, Shield, Smartphone, Calendar, MapPin, Briefcase, Settings, AlertTriangle } from 'lucide-react';

const Switch = ({ checked, onCheckedChange }) => (
  <button
    className={`w-11 h-6 flex items-center rounded-full p-1 ${
      checked ? 'bg-blue-500' : 'bg-gray-300'
    }`}
    onClick={() => onCheckedChange(!checked)}
  >
    <div
      className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform ${
        checked ? 'translate-x-5' : 'translate-x-0'
      }`}
    />
  </button>
);

const SettingsPopup = ({ isOpen, onClose, user, setUser }) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [tempUser, setTempUser] = useState(user);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [securityTip, setSecurityTip] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const securityTips = [
    { tip: "Use a unique password for each of your accounts.", icon: Lock },
    { tip: "Enable two-factor authentication for added security.", icon: Shield },
    { tip: "Regularly update your software and applications.", icon: Settings },
    { tip: "Be cautious when clicking on links in emails or messages.", icon: AlertTriangle },
    { tip: "Use a password manager to generate and store strong passwords.", icon: Briefcase },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setSecurityTip(securityTips[Math.floor(Math.random() * securityTips.length)]);
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleSave = () => {
    setUser(tempUser);
    onClose();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempUser({ ...tempUser, profile_picture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePassword = () => {
    setIsChangingPassword(true);
  };

  const handlePasswordSubmit = () => {
    // Implement password change logic here
    console.log('Password change submitted:', passwordForm);
    setIsChangingPassword(false);
    setPasswordForm({ currentPassword: '', newPassword: '', confirmPassword: '' });
  };

  const tabs = [
    { id: 'profile', icon: User, label: 'Profile' },
    { id: 'security', icon: Lock, label: 'Security' },
    { id: 'notifications', icon: Bell, label: 'Notifications' },
    { id: 'help', icon: HelpCircle, label: 'Help & Support' },
    { id: 'about', icon: Info, label: 'About' },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1001] p-4">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
        <div className="flex items-center justify-between p-3 bg-gradient-to-r from-blue-500 to-purple-600">
          <div className="flex items-center space-x-2">
            <Settings size={20} className="text-white" />
            <h2 className="text-lg font-bold text-white">Settings</h2>
          </div>
          <button onClick={onClose} className="text-white hover:text-gray-200 transition-colors">
            <X size={20} />
          </button>
        </div>
        <div className="flex h-[calc(90vh-48px)]">
          <div className="w-1/4 bg-gray-100 dark:bg-gray-700 p-4 space-y-2">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center space-x-2 w-full p-2 rounded-lg transition-colors ${
                  activeTab === tab.id
                    ? 'bg-gradient-to-r from-blue-500 to-purple-600 text-white'
                    : 'text-gray-700 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                <tab.icon size={18} />
                <span className="font-medium text-sm">{tab.label}</span>
              </button>
            ))}
          </div>
          <div className="w-3/4 p-6 overflow-y-auto">
            {activeTab === 'profile' && (
              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <img
                    src={tempUser.profile_picture || '/api/placeholder/100/100'}
                    alt="Profile"
                    className="w-24 h-24 rounded-full object-cover border-4 border-blue-500"
                  />
                  <label className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition-colors text-sm flex items-center space-x-2">
                    <User size={16} />
                    <span>Change Picture</span>
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </label>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <User size={16} className="inline-block mr-2" />
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={tempUser.name}
                      onChange={(e) => setTempUser({ ...tempUser, name: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <Mail size={16} className="inline-block mr-2" />
                      Email
                    </label>
                    <input
                      type="email"
                      value={tempUser.email || ''}
                      onChange={(e) => setTempUser({ ...tempUser, email: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <Smartphone size={16} className="inline-block mr-2" />
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      value={tempUser.mobile || ''}
                      onChange={(e) => setTempUser({ ...tempUser, mobile: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <User size={16} className="inline-block mr-2" />
                      Gender
                    </label>
                    <select
                      value={tempUser.gender || ''}
                      onChange={(e) => setTempUser({ ...tempUser, gender: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <Calendar size={16} className="inline-block mr-2" />
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      value={tempUser.dob || ''}
                      onChange={(e) => setTempUser({ ...tempUser, dob: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      <MapPin size={16} className="inline-block mr-2" />
                      Location
                    </label>
                    <input
                      type="text"
                      value={tempUser.location || ''}
                      onChange={(e) => setTempUser({ ...tempUser, location: e.target.value })}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                      placeholder="City, Country"
                    />
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'security' && (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold mb-4">Security Settings</h3>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <h4 className="font-medium flex items-center">
                        <Shield size={18} className="mr-2" />
                        Two-Factor Authentication
                      </h4>
                      <p className="text-sm text-gray-500">Add an extra layer of security to your account</p>
                    </div>
                    <Switch checked={twoFactorEnabled} onCheckedChange={setTwoFactorEnabled} />
                  </div>
                  {!isChangingPassword ? (
                    <div>
                      <h4 className="font-medium flex items-center mb-2">
                        <Lock size={18} className="mr-2" />
                        Password
                      </h4>
                      <button
                        onClick={handleChangePassword}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors text-sm flex items-center"
                      >
                        <Lock size={16} className="mr-2" />
                        Change Password
                      </button>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <h4 className="font-medium flex items-center mb-2">
                        <Lock size={18} className="mr-2" />
                        Change Password
                      </h4>
                      <input
                        type="password"
                        placeholder="Current Password"
                        value={passwordForm.currentPassword}
                        onChange={(e) => setPasswordForm({ ...passwordForm, currentPassword: e.target.value })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                      />
                      <input
                        type="password"
                        placeholder="New Password"
                        value={passwordForm.newPassword}
                        onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                      />
                      <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={passwordForm.confirmPassword}
                        onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
                        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm"
                      />
                      <div className="flex space-x-2">
                        <button
                          onClick={handlePasswordSubmit}
                          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors text-sm flex items-center"
                        >
                          <Lock size={16} className="mr-2" />
                          Update Password
                        </button>
                        <button
                          onClick={() => setIsChangingPassword(false)}
                          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition-colors text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4 rounded-r-lg">
                  <div className="flex items-start">
                      <securityTip.icon className="mr-2 flex-shrink-0 mt-1" size={18} />
                      <div>
                        <h4 className="font-bold text-sm">Security Tip</h4>
                        <p className="mt-1 text-sm">{securityTip.tip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'notifications' && (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <Bell size={20} className="mr-2" />
                  Notification Preferences
                </h3>
                <div className="space-y-4">
                  <div className="flex justify-between items-center p-3 bg-gray-100 rounded-lg">
                    <div className="flex items-center">
                      <Mail size={18} className="mr-2 text-blue-500" />
                      <span className="text-sm font-medium">Email Notifications</span>
                    </div>
                    <Switch checked={true} onCheckedChange={() => {}} />
                  </div>
                  <div className="flex justify-between items-center p-3 bg-gray-100 rounded-lg">
                    <div className="flex items-center">
                      <Bell size={18} className="mr-2 text-blue-500" />
                      <span className="text-sm font-medium">Push Notifications</span>
                    </div>
                    <Switch checked={true} onCheckedChange={() => {}} />
                  </div>
                  <div className="flex justify-between items-center p-3 bg-gray-100 rounded-lg">
                    <div className="flex items-center">
                      <Smartphone size={18} className="mr-2 text-blue-500" />
                      <span className="text-sm font-medium">SMS Notifications</span>
                    </div>
                    <Switch checked={false} onCheckedChange={() => {}} />
                  </div>
                </div>
                <div className="mt-6">
                  <h4 className="font-medium mb-2 flex items-center">
                    <Settings size={18} className="mr-2" />
                    Notification Settings
                  </h4>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox text-blue-500" />
                      <span className="text-sm">Account updates</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox text-blue-500" />
                      <span className="text-sm">New features and updates</span>
                    </label>
                    <label className="flex items-center space-x-2">
                      <input type="checkbox" className="form-checkbox text-blue-500" />
                      <span className="text-sm">Marketing and promotions</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'help' && (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <HelpCircle size={20} className="mr-2" />
                  Help & Support
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <button className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 bg-blue-100 p-3 rounded-lg transition-colors">
                    <HelpCircle size={18} />
                    <span className="text-sm font-medium">FAQs</span>
                  </button>
                  <button className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 bg-blue-100 p-3 rounded-lg transition-colors">
                    <Mail size={18} />
                    <span className="text-sm font-medium">Contact Support</span>
                  </button>
                  <button className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 bg-blue-100 p-3 rounded-lg transition-colors">
                    <Smartphone size={18} />
                    <span className="text-sm font-medium">Call Support</span>
                  </button>
                  <button className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 bg-blue-100 p-3 rounded-lg transition-colors">
                    <Info size={18} />
                    <span className="text-sm font-medium">User Guide</span>
                  </button>
                </div>
                <div className="bg-gray-100 p-4 rounded-lg mt-4">
                  <h4 className="font-medium mb-2 text-sm flex items-center">
                    <HelpCircle size={16} className="mr-2" />
                    Quick Support
                  </h4>
                  <p className="text-sm text-gray-600">
                    Having issues? Email us at support@bytebuzz.com or call our 24/7 support line at +1 (800) 123-4567.
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'about' && (
              <div className="space-y-6">
                <h3 className="text-lg font-semibold mb-4 flex items-center">
                  <Info size={20} className="mr-2" />
                  About Byte Buzz
                </h3>
                <p className="text-sm">Byte Buzz is a cutting-edge dashboard application designed to streamline your workflow and boost productivity.</p>
                <div>
                  <h4 className="font-medium mb-2 text-sm flex items-center">
                    <Settings size={16} className="mr-2" />
                    Version
                  </h4>
                  <p className="text-sm">1.0.0</p>
                </div>
                <div>
                  <h4 className="font-medium mb-2 text-sm flex items-center">
                    <Lock size={16} className="mr-2" />
                    License
                  </h4>
                  <p className="text-sm">© 2024 Byte Buzz Inc. All rights reserved.</p>
                </div>
                <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors text-sm flex items-center">
                  <Settings size={16} className="mr-2" />
                  Check for Updates
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end p-4 bg-gray-100 dark:bg-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100 mr-2 rounded-lg text-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg hover:from-blue-600 hover:to-purple-700 transition-colors text-sm flex items-center"
          >
            <Settings size={16} className="mr-2" />
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;