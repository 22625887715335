import React, { useState, useEffect, useRef } from 'react';
import { 
  Home, 
  ShoppingBag, 
  BarChart2, 
  FileBarChart, 
  Settings, 
  Database, 
  Bell, 
  Mail, 
  User, 
  Menu, 
  LogOut, 
  Container, 
  CheckCircle, 
  AlertTriangle, 
  Info, 
  Clock, 
  X, 
  Trash2,
  ChevronDown,
  ChevronRight
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import PoTable from './PoTable';
import AdminDashboard from './AdminDashboard';
import './Dashboard.css';
import DashboardContent from './SummaryPage';
import UnderConstruction from './UnderConstruction';
import SettingsPopup from './settings';
import StatusDialog from './Dialog';
import AuthorizationDenied from './Authorizationdenied';
import FullScreenPowerBIEmbed from './powerbi';

const Dashboard = () => {

  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [user, setUser] = useState({ name: '', imageUrl: '', gender: 'male' });
  const [activeContent, setActiveContent] = useState('Home');
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [mailNotifications, setMailNotifications] = useState([
    { id: 1, message: 'New email from John', read: false, type: 'info', time: '2 min ago' },
    { id: 2, message: 'Meeting reminder', read: false, type: 'warning', time: '1 hour ago' },
    { id: 3, message: 'Project update', read: false, type: 'success', time: '3 hours ago' },
  ]);
  const [bellNotifications, setBellNotifications] = useState([
    { id: 1, message: 'New order received', read: false, type: 'success', time: '5 min ago' },
    { id: 2, message: 'Payment processed', read: false, type: 'info', time: '30 min ago' },
    { id: 3, message: 'Stock alert', read: false, type: 'warning', time: '1 hour ago' },
    { id: 4, message: 'New feature available', read: false, type: 'info', time: '2 hours ago' },
    { id: 5, message: 'System maintenance tonight', read: false, type: 'warning', time: '4 hours ago' },
  ]);
  const [showMailNotifications, setShowMailNotifications] = useState(false);
  const [showBellNotifications, setShowBellNotifications] = useState(false);
  
  const [logoutStatus, setLogoutStatus] = useState(null);
  const mailNotificationRef = useRef(null);
  const bellNotificationRef = useRef(null);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    // Set Sales as default when Ecom Dashboard is first expanded
    if (expandedMenu === 'Ecom Dashboard' && activeContent === 'Home') {
      setActiveContent('Sales');
    }
  }, [expandedMenu, activeContent]);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      setUser({
        name: `${userData.first_name} ${userData.last_name}`,
        profile_picture: userData.profile_picture,
        gender: userData.gender || 'male'
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mailNotificationRef.current && !mailNotificationRef.current.contains(event.target)) {
        setShowMailNotifications(false);
      }
      if (bellNotificationRef.current && !bellNotificationRef.current.contains(event.target)) {
        setShowBellNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems = [
    { icon: Home, text: 'Home' },
    { icon: ShoppingBag, text: 'Order Management' },
    { icon: BarChart2, text: 'DSA' },
    { 
      icon: Database,
      text: 'Ecom Dashboard',
      subItems: [
        { text: 'Sales' },
        { text: 'Marketing' }
      ]
    },
    { icon: User, text: 'Admin' },
  ];



  const renderContent = () => {
    switch (activeContent) {
      case 'Home':
        return <DashboardContent />;
      case 'Order Management':
        return <PoTable />;
      case 'DSA':
        return <FullScreenPowerBIEmbed reportType="dsa" />;
      case 'Sales':
        return <FullScreenPowerBIEmbed reportType="sales" />;
      case 'Marketing':
        return <FullScreenPowerBIEmbed reportType="marketing" />;
      case 'Admin':
        return <AdminDashboard />;
      default:
        return <UnderConstruction />;
    }
  };
  

  const handleMenuItemClick = (menuText) => {
    if (menuText === 'Ecom Dashboard') {
      setExpandedMenu(expandedMenu === 'Ecom Dashboard' ? null : 'Ecom Dashboard');
      if (expandedMenu !== 'Ecom Dashboard') {
        setActiveContent('Sales'); // Set Sales as default when expanding
      }
    } else {
      setActiveContent(menuText);
      setExpandedMenu(null); // Close expanded menu when clicking other items
    }
  };

  const handleSubItemClick = (subItemText) => {
    setActiveContent(subItemText);
  };

  const handleLogout = () => {
    setLogoutStatus('loading');
    
    // Simulating an API call for logout
    setTimeout(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setLogoutStatus('success');
      
      // Navigate to login page after a short delay
      setTimeout(() => {
        navigate('/login');
      }, 1000);
    }, 1000);
  };
  const handleLogoutSuccess = () => {
    setLogoutStatus(null);
    navigate('/login');
  };
  const toggleMailNotifications = () => {
    setShowMailNotifications(!showMailNotifications);
    setShowBellNotifications(false);
  };

  const toggleBellNotifications = () => {
    setShowBellNotifications(!showBellNotifications);
    setShowMailNotifications(false);
  };

  const clearAllNotifications = (type) => {
    if (type === 'mail') {
      setMailNotifications([]);
    } else {
      setBellNotifications([]);
    }
  };

  const removeNotification = (type, id) => {
    if (type === 'mail') {
      setMailNotifications(mailNotifications.filter(notif => notif.id !== id));
    } else {
      setBellNotifications(bellNotifications.filter(notif => notif.id !== id));
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle size={14} className="notification-icon success" />;
      case 'warning':
        return <AlertTriangle size={14} className="notification-icon warning" />;
      case 'info':
      default:
        return <Info size={14} className="notification-icon info" />;
    }
  };
  return (
    <div className="container">
      <div className="sidebar" style={{ width: isMobile ? '0' : '215px' }}>
        <div className="logo">Byte Buzz</div>
        {menuItems.map((item, index) => (
          <div key={index}>
            <div 
              className={`menu-item ${activeContent === item.text || (item.text === 'Ecom Dashboard' && (activeContent === 'Sales' || activeContent === 'Marketing')) ? 'active' : ''}`} 
              onClick={() => handleMenuItemClick(item.text)}
            >
              <item.icon size={18} />
              <span className="menu-text">{item.text}</span>
              {item.subItems && (
                expandedMenu === item.text ? 
                <ChevronDown size={18} className="menu-expand-icon" /> : 
                <ChevronRight size={18} className="menu-expand-icon" />
              )}
            </div>
            {item.subItems && expandedMenu === item.text && (
              <div className="sub-menu expanded">
                {item.subItems.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className={`sub-menu-item ${activeContent === subItem.text ? 'active' : ''}`}
                    onClick={() => handleSubItemClick(subItem.text)}
                  >
                    <span className="sub-menu-text">{subItem.text}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="logout-button" onClick={handleLogout}>
          <LogOut size={18} />
          <span>Logout</span>
        </div>
      </div>
      <div className="main-content" style={{ marginLeft: isMobile ? '0' : '215px' }}>
        <div className="top-nav" style={{ left: isMobile ? '0' : '215px' }}>
          <div className="left-nav-content">
            <div className="menu-toggle" onClick={() => setIsMobile(!isMobile)}>
              <Container size={24} />
            </div>
            <div className="page-title">{activeContent}</div>
          </div>
          <div className="user-profile">
            <div className="nav-icons">
              <div className="icon-with-badge" ref={bellNotificationRef}>
                <Bell size={18} onClick={toggleBellNotifications} className="nav-icon" />
                {bellNotifications.length > 0 && <span className="notification-badge">{bellNotifications.length}</span>}
                {showBellNotifications && (
                  <div className="notification-dropdown">
                    <div className="notification-header">
                      <h3><Bell size={14} /> Notifications</h3>
                      <button onClick={() => clearAllNotifications('bell')}><Trash2 size={14} /> Clear All</button>
                    </div>
                    <div className="notification-list">
                      {bellNotifications.map((notif) => (
                        <div key={notif.id} className="notification-item">
                          <div className="notification-content">
                            {getNotificationIcon(notif.type)}
                            <div className="notification-text">
                              <span className="notification-message">{notif.message}</span>
                              <span className="notification-time"><Clock size={12} /> {notif.time}</span>
                            </div>
                          </div>
                          <X size={14} onClick={() => removeNotification('bell', notif.id)} className="remove-icon" />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="icon-with-badge" ref={mailNotificationRef}>
                <Mail size={18} onClick={toggleMailNotifications} className="nav-icon" />
                {mailNotifications.length > 0 && <span className="notification-badge">{mailNotifications.length}</span>}
                {showMailNotifications && (
                  <div className="notification-dropdown">
                    <div className="notification-header">
                      <h3><Mail size={14} /> Messages</h3>
                      <button onClick={() => clearAllNotifications('mail')}><Trash2 size={14} /> Clear All</button>
                    </div>
                    <div className="notification-list">
                      {mailNotifications.map((notif) => (
                        <div key={notif.id} className="notification-item">
                          <div className="notification-content">
                            {getNotificationIcon(notif.type)}
                            <div className="notification-text">
                              <span className="notification-message">{notif.message}</span>
                              <span className="notification-time"><Clock size={12} /> {notif.time}</span>
                            </div>
                          </div>
                          <X size={14} onClick={() => removeNotification('mail', notif.id)} className="remove-icon" />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Settings size={18} className="nav-icon" onClick={() => setIsSettingsOpen(true)} />
            </div>
            <img
              src={user.profile_picture ||
                (user.gender === 'male'
                  ? 'https://img.freepik.com/free-psd/3d-illustration-person-with-glasses_23-2149436191.jpg?w=740&t=st=1724138260~exp=1724138860~hmac=bb60772a8bf30ad8c693c010624a1d5c9f01b944f14f3e55334ae6ae23b8b4a1'
                  : 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436178.jpg?size=626&ext=jpg&ga=GA1.1.1940202705.1721411693&semt=ais_hybrid')}
              alt="User Profile"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = user.gender === 'female'
                  ? 'https://img.freepik.com/free-psd/3d-illustration-person-with-glasses_23-2149436191.jpg?w=740&t=st=1724138260~exp=1724138860~hmac=bb60772a8bf30ad8c693c010624a1d5c9f01b944f14f3e55334ae6ae23b8b4a1'
                  : 'https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436178.jpg?size=626&ext=jpg&ga=GA1.1.1940202705.1721411693&semt=ais_hybrid';
              }}
            />
            <span>{user.name}</span>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="content-scaler">
            {renderContent()}
          </div>
        </div>
      </div>
      <SettingsPopup
  isOpen={isSettingsOpen}
  onClose={() => setIsSettingsOpen(false)}
  user={user}
  setUser={setUser}
/>
<StatusDialog
        status={logoutStatus}
        onSuccess={handleLogoutSuccess}
        onClose={() => setLogoutStatus(null)}
        errorMessage="An error occurred during logout"
        action="logout"
      />
    </div>
    
  );
};

export default Dashboard;