import axios from 'axios';

let API_BASE_URL = process.env.REACT_APP_API_URL;

// if (API_BASE_URL) {
//     API_BASE_URL = API_BASE_URL.slice(1, -2);
// }

export const loginUser = async (email, password) => {
    try {
        console.log(API_BASE_URL);  // Check the modified environment variable URL
        const response = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
        console.log(response.data);
        return response.data;  // Ensure this includes { token, user: { name, imageUrl } }
    } catch (error) {
        console.error('Error logging in user:', error);
        throw error;
    }
};

export default loginUser;
