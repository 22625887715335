import React, { useState, useEffect, useCallback } from 'react';
import { fetchDataFromAPI, fetchPodata } from '../routes/AppRoutes';
import * as XLSX from 'xlsx';
import { ChevronDown, ChevronRight, RotateCw, RefreshCw, Check, FileText, Download, Edit, Trash2, Search, X } from 'lucide-react';
import 'react-datepicker/dist/react-datepicker.css';
import './PoTable.css';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Edit Order Details</h2>
          <button onClick={onClose} className="modal-close">
            <X size={24} />
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

const PoTable = () => {
  const [currentPlatform, setCurrentPlatform] = useState('Amazon');
  const [columns, setColumns] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filter, setFilter] = useState('');
  const [activeToggle, setActiveToggle] = useState(null);
  const [poNumberSearch, setPoNumberSearch] = useState('');
  const [toppledData, setToppledData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [editingData, setEditingData] = useState(null);

  const platforms = ['Amazon', 'Flipkart', 'Blinkit', 'Bigbasket', 'zepto'];
  const renderStatus = (status) => {
    const statusClasses = {
      Pending: 'status-pending',
      Approved: 'status-approved',
      Shipped: 'status-shipped',
      Delivered: 'status-delivered',
      Canceled: 'status-canceled'
    };
    return <span className={`status-badge ${statusClasses[status] || ''}`}>{status}</span>;
  };

  const loadOrders = useCallback(async (platform) => {
    try {
      const data = await fetchDataFromAPI(platform);
      if (Array.isArray(data) && data.length > 0) {
        const orderData = data[0];
        const filteredData = orderData.filter(order =>
          (filter === '' || order.Status === filter) &&
          (poNumberSearch === '' || order.PoNumber.toLowerCase().includes(poNumberSearch.toLowerCase()))
        );
        if (filteredData.length > 0) {
          setColumns(Object.keys(filteredData[0]));
          setOrders(filteredData.map(order => ({ ...order, selected: false })));
        }
      }
    } catch (error) {
      console.error('Error loading orders:', error);
    }
  }, [filter, poNumberSearch]);

  useEffect(() => {
    loadOrders(currentPlatform);
  }, [loadOrders, currentPlatform]);

  const handlePlatformChange = (platform) => {
    setCurrentPlatform(platform);
    loadOrders(platform);
  };

  const toggleRow = async (index) => {
    if (activeToggle === index) {
      setActiveToggle(null);
    } else {
      setActiveToggle(index);
      const orderNumber = orders[index].PoNumber;
      try {
        const toppledData = await fetchPodata(orderNumber);
        setToppledData(prevState => ({
          ...prevState,
          [orderNumber]: toppledData
        }));
      } catch (error) {
        console.error('Error loading toppled data:', error);
      }
    }
  };

  const toggleSelectAll = () => {
    const newState = !selectAll;
    const updatedOrders = orders.map(order => ({
      ...order,
      selected: newState
    }));
    setOrders(updatedOrders);
    setSelectAll(newState);
  };

  const handleCheckboxChange = (index) => {
    const updatedOrders = [...orders];
    updatedOrders[index].selected = !updatedOrders[index].selected;
    setOrders(updatedOrders);

    const allSelected = updatedOrders.every(order => order.selected);
    setSelectAll(allSelected);
  };

  const exportToExcel = () => {
    const selectedOrders = orders.filter(order => order.selected);
    if (selectedOrders.length === 0) {
      alert('Please select at least one order to export.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(selectedOrders);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveExcelFile(excelBuffer, 'exported_data.xlsx');
  };

  const saveExcelFile = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      console.log('Parsed Excel data:', parsedData);
      // Process the parsed data as needed
    };
    reader.readAsArrayBuffer(file);
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString();
  };

  const renderCellContent = (value) => {
    if (value === null || value === undefined || value === '') {
      return '';
    } else if (typeof value === 'string' && value.startsWith('2024-')) {
      return formatDate(value);
    } else if (typeof value === 'object') {
      return JSON.stringify(value);
    } else {
      return value;
    }
  };

 

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditingOrder(null);
    setEditingData(null);
  };

  const handleSaveEdit = () => {
    // Here you would typically send the updated data to your backend
    console.log('Saving edited data:', editingOrder, editingData);
    // Update the local state
    setOrders(prevOrders => prevOrders.map(order => 
      order.PoNumber === editingOrder.PoNumber ? { ...order, ...editingOrder } : order
    ));
    setToppledData(prevState => ({
      ...prevState,
      [editingOrder.PoNumber]: editingData
    }));
    handleCloseEditModal();
  };

  const handleEditDataChange = (index, field, value) => {
    setEditingData(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
  };

  const handleEditOrderChange = (field, value) => {
    setEditingOrder(prevOrder => ({
      ...prevOrder,
      [field]: value
    }));
  };

  const handleDeleteClick = (orderNumber) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      // Here you would typically send a delete request to your backend
      console.log('Deleting order:', orderNumber);
      // Update the local state
      setOrders(prevOrders => prevOrders.filter(order => order.PoNumber !== orderNumber));
    }
  };
  const renderEditModalContent = () => {
    if (!editingOrder || !editingData) return null;

    return (
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-semibold mb-2">Order Information</h3>
          <table className="w-full border-collapse">
            <tbody>
              {Object.entries(editingOrder).map(([key, value]) => (
                <tr key={key} className="border-b">
                  <td className="py-2 px-4 font-medium text-gray-700 w-1/3">{key}</td>
                  <td className="py-2 px-4">
                    <input 
                      type="text" 
                      value={value} 
                      onChange={(e) => handleEditOrderChange(key, e.target.value)}
                      className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Order Details</h3>
          {editingData.map((item, index) => (
            <div key={index} className="mb-4 bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium text-gray-700 mb-2">Item {index + 1}</h4>
              <table className="w-full border-collapse">
                <tbody>
                  {Object.entries(item).map(([field, value]) => (
                    <tr key={field} className="border-b last:border-b-0">
                      <td className="py-2 px-4 font-medium text-gray-700 w-1/3">{field}</td>
                      <td className="py-2 px-4">
                        <input 
                          type="text" 
                          value={value} 
                          onChange={(e) => handleEditDataChange(index, field, e.target.value)}
                          className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <button 
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
            onClick={handleCloseEditModal}
          >
            Cancel
          </button>
          <button 
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            onClick={handleSaveEdit}
          >
            Save Changes
          </button>
        </div>
      </div>
    );
  };
  const renderToppledData = (orderNumber) => {
    const data = toppledData[orderNumber];
    if (!data || data.length === 0) return null;

    const columnNames = Object.keys(data[0]);

    return (
      <div className="toppled-data">
        <h6 className="toppled-data-title">Order Details</h6>
        <div className="table-responsive">
          <table className="table table-sm toppled-table">
            <thead>
              <tr>
                {columnNames.map((columnName, index) => (
                  <th key={index}>{columnName}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {columnNames.map((columnName, cellIndex) => (
                    <td key={cellIndex}>{renderCellContent(item[columnName])}</td>
                  ))}
                  <td>
                    <button 
                      className="btn btn-sm btn-outline-primary me-2"
                      onClick={() => handleEditClick(orderNumber, rowIndex)}
                    >
                      <Edit size={14} />
                      <span>Edit</span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const handleEditClick = (orderNumber, rowIndex = null) => {
    const orderToEdit = orders.find(order => order.PoNumber === orderNumber);
    setEditingOrder(orderToEdit);
    fetchPodata(orderNumber).then(data => {
      setEditingData(rowIndex !== null ? [data[rowIndex]] : data);
      setShowEditModal(true);
    }).catch(error => {
      console.error('Error fetching order details:', error);
      alert('Failed to load order details. Please try again.');
    });
  };

  return (
    <div className="container-fluid mt-4">
      
      <div className="card mb-4">
        <div className="card-body py-3">
          <h5 className="card-title mb-3">Select Platform</h5>
          <div className="platform-selector">
            {platforms.map((platform) => (
              <button
                key={platform}
                className={`platform-btn ${currentPlatform === platform ? 'active' : ''}`}
                onClick={() => handlePlatformChange(platform)}
                data-platform={platform}
              >
                {platform}
              </button>
            ))}
          </div>
        </div>
      </div>
      
      <div className="card mb-3">
        <div className="card-body">
          <div className="filters-actions-row">
            <div>
              <select 
                id="statusFilter"
                className="form-select form-select-sm"
                value={filter} 
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="">All Status</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Shipped">Shipped</option>
                <option value="Delivered">Delivered</option>
                <option value="Canceled">Canceled</option>
              </select>
            </div>
            <div>
              <div className="input-group input-group-sm">
                <span className="input-group-text"><Search size={14} /></span>
                <input 
                  id="poSearch"
                  type="text" 
                  className="form-control form-control-sm" 
                  placeholder="Search PO Number..." 
                  value={poNumberSearch}
                  onChange={(e) => setPoNumberSearch(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="actions-group">
                <button className="btn btn-sm btn-primary" onClick={() => loadOrders(currentPlatform)} title="Load Orders">
                  <RefreshCw size={14} className="me-1" />
                  <span>Load</span>
                </button>
                <button className="btn btn-sm btn-secondary" title="Hard Refresh" onClick={() => window.location.reload()}>
                  <RotateCw size={14} className="me-1" />
                  <span>Refresh</span>
                </button>
                <button className="btn btn-sm btn-success" title="Approve Orders">
                  <Check size={14} className="me-1" />
                  <span>Approve</span>
                </button>
                <button className="btn btn-sm btn-info" onClick={() => document.getElementById('fileInput').click()} title="Upload Excel">
                  <FileText size={14} className="me-1" />
                  <span>Upload</span>
                </button>
                <input 
                  id="fileInput" 
                  type="file" 
                  accept=".xlsx,.xls" 
                  onChange={handleFileUpload} 
                  style={{ display: 'none' }} 
                />
                <button className="btn btn-sm btn-dark" onClick={exportToExcel} title="Export to Excel">
                  <Download size={14} className="me-1" />
                  <span>Export</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th></th>
                  {columns.map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, index) => (
                  <React.Fragment key={index}>
                    <tr className={activeToggle === index ? 'active-row' : ''}>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={order.selected}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td>
                        <button 
                          className="btn btn-sm btn-link p-0 toggle-btn"
                          onClick={() => toggleRow(index)}
                        >
                          {activeToggle === index ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                        </button>
                      </td>
                      {columns.map((column, colIndex) => (
                        <td key={colIndex}>
                          {column === 'Status' ? renderStatus(order[column]) : renderCellContent(order[column])}
                        </td>
                      ))}
                      <td>
                        <div className="d-flex justify-content-start align-items-center">
                          <button 
                            className="btn btn-sm btn-outline-primary me-2"
                            onClick={() => handleEditClick(order.PoNumber)}
                          >
                            <Edit size={14} />
                            <span>Edit</span>
                          </button>
                          <button 
                            className="btn btn-sm btn-outline-danger"
                            onClick={() => handleDeleteClick(order.PoNumber)}
                          >
                            <Trash2 size={14} />
                            <span>Delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                    {activeToggle === index && (
                      <tr className="details-row">
                        <td colSpan={columns.length + 3}>
                          {renderToppledData(order.PoNumber)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={showEditModal} onClose={handleCloseEditModal}>
        {renderEditModalContent()}
      </Modal>
    </div>
  );
};

export default PoTable;