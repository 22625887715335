import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './SummaryPage.css';
import { fetchDashboardData } from '../routes/AppRoutes';

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [chartOptions, setChartOptions] = useState({
    platformOptions: null,
    statusOptions: null,
    salesOptions: null
  });
  const [dashboardData, setDashboardData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [filters, setFilters] = useState({
    Distribution: 'All',
    platform: 'All',
    fromDate: '',
    toDate: '',
    status: 'All'
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    fetchData();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [filters]);

  const fetchData = async () => {
    try {
      const response = await fetchDashboardData(filters);
      const data = response[0];
      const filteredData = applyFilters(data);
      setDashboardData(filteredData);
      initializeCharts(filteredData);
      calculateSummaryData(filteredData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const applyFilters = (data) => {
    return data.filter(order => {
      const orderDate = new Date(order.order_date).setHours(0, 0, 0, 0);
      const fromDate = filters.fromDate ? new Date(filters.fromDate).setHours(0, 0, 0, 0) : null;
      const toDate = filters.toDate ? new Date(filters.toDate).setHours(23, 59, 59, 999) : null;
  
      return (
        (filters.Distribution === 'All' || order.Distribution === filters.Distribution) &&
        (filters.platform === 'All' || order.platform === filters.platform) &&
        (filters.status === 'All' || order.status === filters.status) &&
        (!fromDate || orderDate >= fromDate) &&
        (!toDate || orderDate <= toDate)
      );
    });
  };
  


  const calculateSummaryData = (data) => {
    const summary = data.reduce((acc, order) => {
      if (!acc[order.platform]) {
        acc[order.platform] = {
          totalOrders: 0,
          totalValue: 0,
          totalQuantity: 0,
          totalVolume: 0,
          approvedOrders: 0,
          pendingOrders: 0
        };
      }
      acc[order.platform].totalOrders += 1;
      acc[order.platform].totalValue += order.total_sales_value;
      acc[order.platform].totalQuantity += order.quantity;
      acc[order.platform].totalVolume += order.volume;
      if (order.status === 'Approved') {
        acc[order.platform].approvedOrders += 1;
      } else if (order.status === 'Pending') {
        acc[order.platform].pendingOrders += 1;
      }
      return acc;
    }, {});

    setSummaryData(Object.entries(summary).map(([platform, data]) => ({
      platform,
      ...data,
      status: `${data.approvedOrders} Approved, ${data.pendingOrders} Pending`
    })));
  };
  const initializeCharts = (data) => {
    const platformData = calculatePlatformData(data);
    const statusData = calculateStatusData(data);
    const salesData = calculateSalesData(data);

    const platformOptions = {
      series: platformData.series,
      chart: {
        type: 'donut',
        height: 350
      },
      labels: platformData.labels,
      colors: ['#3498db', '#e67e22', '#9b59b6', '#1abc9c', '#34495e'],
      title: {
        text: 'Orders by Platform',
        align: 'center'
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const statusOptions = {
      series: statusData.series,
      chart: {
        type: 'donut',
        height: 350
      },
      labels: statusData.labels,
      colors: ['#2ecc71', '#f39c12'],
      title: {
        text: 'Order Status Distribution',
        align: 'center'
      },
      legend: {
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                }
              }
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    };

    const salesOptions = {
      series: salesData.series,
      chart: {
        height: 350,
        type: 'line'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: salesData.categories
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy'
        },
      },
      title: {
        text: 'Orders by Date and Platform',
        align: 'center'
      },
    };

    setChartOptions({
      platformOptions,
      statusOptions,
      salesOptions
    });
  };

  const calculatePlatformData = (data) => {
    const platformCounts = data.reduce((acc, order) => {
      acc[order.platform] = (acc[order.platform] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(platformCounts),
      series: Object.values(platformCounts)
    };
  };

  const calculateStatusData = (data) => {
    const statusCounts = data.reduce((acc, order) => {
      acc[order.status] = (acc[order.status] || 0) + 1;
      return acc;
    }, {});

    return {
      labels: Object.keys(statusCounts),
      series: Object.values(statusCounts)
    };
  };

  const calculateSalesData = (data) => {
    const salesByPlatform = data.reduce((acc, order) => {
      if (!acc[order.platform]) {
        acc[order.platform] = {};
      }
      const date = new Date(order.order_date).toISOString().split('T')[0];
      acc[order.platform][date] = (acc[order.platform][date] || 0) + order.total_sales_value;
      return acc;
    }, {});

    const categories = [...new Set(data.map(order => new Date(order.order_date).toISOString().split('T')[0]))].sort();

    const series = Object.keys(salesByPlatform).map(platform => ({
      name: platform,
      data: categories.map(date => salesByPlatform[platform][date] || 0)
    }));

    return { categories, series };
  };

  const calculateTotalOrders = () => dashboardData.length;
  const calculateTotalWeightage = () => dashboardData.reduce((sum, order) => sum + order.volume, 0).toFixed(2);
  const calculateApprovedOrders = () => dashboardData.filter(order => order.status === 'Approved').length;
  const calculatePendingOrders = () => dashboardData.filter(order => order.status === 'Pending').length;

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const menuItems = [
    { 
      icon: 'fas fa-home', 
      text: 'Dashboard',
      subItems: ['Overview', 'Analytics', 'Forecasts']
    },
    { 
      icon: 'fas fa-chart-bar', 
      text: 'Reports',
      subItems: ['Sales', 'Inventory', 'Performance']
    },
    { 
      icon: 'fas fa-lightbulb', 
      text: 'Insights',
      subItems: ['Trends', 'Recommendations', 'Market Analysis']
    },
    { 
      icon: 'fas fa-concierge-bell', 
      text: 'Services',
      subItems: ['Customer Support', 'Logistics', 'Marketing']
    },
    { 
      icon: 'fas fa-cog', 
      text: 'Settings',
      subItems: ['Account', 'Preferences', 'Integrations']
    },
  ];


  return (

    <div>
      {/* <div className="sidebar" style={{ width: isMobile ? '0' : '250px' }}>
        <div className="logo">Byte Buzz</div>
        {menuItems.map((item, index) => (
          <div key={index} className="menu-item-container">
            <div 
              className={`menu-item ${activeMenu === index ? 'active' : ''}`} 
              onClick={() => setActiveMenu(activeMenu === index ? null : index)}
            >
              <i className={item.icon}></i> 
              <span className="menu-text">{item.text}</span>
              {item.subItems && <i className={`fas fa-chevron-${activeMenu === index ? 'up' : 'down'}`}></i>}
            </div>
            {activeMenu === index && item.subItems && (
              <div className="sub-menu">
                {item.subItems.map((subItem, subIndex) => (
                  <div key={subIndex} className="sub-menu-item">{subItem}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div> */}
        <div className="main-content" >
          {/* <div className="top-nav" style={{ left: isMobile ? '0' : '250px' }}>
            <div className="search-bar">
              <i className="fas fa-search"></i>
              <input type="text" placeholder="Search..." />
            </div>
            <div className="user-profile">
              <div className="nav-icons">
                <i className="far fa-bell"></i>
                <i className="far fa-envelope"></i>
                <i className="fas fa-cog"></i>
                <i className="fas fa-question-circle"></i>
              </div>
              <img src="https://randomuser.me/api/portraits/women/65.jpg" alt="User Profile" />
              <span>Shelly</span>
            </div>
          </div> */}
          <div className="dashboard-content">
            <div className="filters">
              <div className="filter">

              </div>
              <div className="filter">
                <label>Distribution</label>
                <select onChange={(e) => handleFilterChange('Distribution', e.target.value)}>
                  <option value="All">All</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="Modern Trade">Modern Trade</option>
                </select>
              </div>
              <div className="filter">
                <label>Platform</label>
                <select onChange={(e) => handleFilterChange('platform', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Flipkart">Flipkart</option>
                  <option value="Amazon">Amazon</option>
                  <option value="Blinkit">Blinkit</option>
                  <option value="Big Basket">Big Basket</option>
                  <option value="Zepto">Zepto</option>
                </select>
              </div>
              <div className="filter">
                <label>From Date</label>
                <input type="date" onChange={(e) => handleFilterChange('fromDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>To Date</label>
                <input type="date" onChange={(e) => handleFilterChange('toDate', e.target.value)} />
              </div>
              <div className="filter">
                <label>Status</label>
                <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                  <option value="All">All</option>
                  <option value="Approved">Approved</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
            </div>
            <div className="metrics">
              <div className="metric-card">
                <div className="metric-icon" style={{ color: 'var(--accent-color)' }}>
                  <i className="fas fa-shopping-cart"></i>
                </div>
                <div className="metric-content">
                  <h3>Total Orders</h3>
                  <p>{calculateTotalOrders()}</p>
                </div>
              </div>
              <div className="metric-card">
                <div className="metric-icon" style={{ color: 'var(--success-color)' }}>
                  <i className="fas fa-weight-hanging"></i>
                </div>
                <div className="metric-content">
                  <h3>Total Weightage</h3>
                  <p>{calculateTotalWeightage()} kg</p>
                </div>
              </div>
              <div className="metric-card">
                <div className="metric-icon" style={{ color: 'var(--warning-color)' }}>
                  <i className="fas fa-check-circle"></i>
                </div>
                <div className="metric-content">
                  <h3>Approved Orders</h3>
                  <p>{calculateApprovedOrders()}</p>
                </div>
              </div>
              <div className="metric-card">
                <div className="metric-icon" style={{ color: 'var(--danger-color)' }}>
                  <i className="fas fa-clock"></i>
                </div>
                <div className="metric-content">
                  <h3>Pending Approval</h3>
                  <p>{calculatePendingOrders()}</p>
                </div>
              </div>
            </div>
            <div className="chart-container">
              <div className="chart-area">
                {chartOptions.platformOptions && (
                  <ReactApexChart 
                    options={chartOptions.platformOptions} 
                    series={chartOptions.platformOptions.series} 
                    type="donut" 
                    height={350} 
                  />
                )}
              </div>
              <div className="chart-area">
                {chartOptions.statusOptions && (
                  <ReactApexChart 
                    options={chartOptions.statusOptions} 
                    series={chartOptions.statusOptions.series} 
                    type="donut" 
                    height={350} 
                  />
                )}
              </div>
            </div>
            <div className="table-area">
            <h2>Summary Table</h2>
            <table>
              <thead>
                <tr>
                  <th>Platform</th>
                  <th>Total Orders</th>
                  <th>Total Value</th>
                  <th>Total Quantity</th>
                  <th>Total Volume</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {summaryData.map((platform) => (
                  <tr key={platform.platform}>
                    <td>{platform.platform}</td>
                    <td>{platform.totalOrders}</td>
                    <td>₹{platform.totalValue.toFixed(2)}</td>
                    <td>{platform.totalQuantity}</td>
                    <td>{platform.totalVolume.toFixed(2)} kg</td>
                    <td>
                      <div className="status-container">
                        <span className="status-pill status-approved">{platform.approvedOrders} Approved</span>
                        <span className="status-pill status-pending">{platform.pendingOrders} Pending</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
            <div className="chart-area">
              {chartOptions.salesOptions && (
                <ReactApexChart 
                  options={chartOptions.salesOptions} 
                  series={chartOptions.salesOptions.series} 
                  type="line" 
                  height={350} 
                />
              )}
            </div>  
          </div>
        </div>
      </div>
    );
  };
  export default Dashboard;