import React from 'react';

const FullScreenPowerBIEmbed = ({ reportType }) => {
  const urls = {
    dsa: "https://app.powerbi.com/reportEmbed?reportId=e0718892-f69d-44de-9e9d-9056cb8d98fe&autoAuth=true&ctid=e14e73eb-5251-4388-8d67-8f9f2e2d5a46",
    sales: "https://app.powerbi.com/reportEmbed?reportId=410ab61c-91d5-4e57-9d1d-11c850c0da83&autoAuth=true&ctid=e14e73eb-5251-4388-8d67-8f9f2e2d5a46",
    marketing: "https://app.powerbi.com/reportEmbed?reportId=8d99e7e5-8f4c-4d62-89a5-196ec28d9f6c&autoAuth=true&ctid=e14e73eb-5251-4388-8d67-8f9f2e2d5a46",
  };

  const reportUrl = urls[reportType] || urls.dsa; // Default to 'dsa' if the type is not matched

  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }}>
      <iframe
        title={reportType.charAt(0).toUpperCase() + reportType.slice(1)}
        width="100%"
        height="100%"
        src={reportUrl}
        frameBorder="0"
        allowFullScreen
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default FullScreenPowerBIEmbed;
